import React from "react";

import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createFullDateTimeToken } from "../functions/DateTime";
import { fetchUserData, readCredentialAPI, readEmployeeAPI, readMachineAPI, readProductAPI, userLogin } from "./../functions/Api";
import { urlLogOut } from "./../settings";
import { PERMISSON } from "./permisson";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [doctorsUser, setDoctorsUser] = useLocalStorage("doctorUsers", null);
  const [authorities, setAuthorities] = useLocalStorage("authorities", null);
  const [credentials, setCredentials] = useLocalStorage("credentials", null);
  const [machines, setMachines] = useLocalStorage("machines", null);
  const [products, setProducts] = useLocalStorage("products", null);
  const [urlLogIn, setUrlLogIn] = useLocalStorage("urlLogIn", null);
  const [doctors, setDoctors] = useLocalStorage("doctors", null);
  const [users, setUsers] = useLocalStorage("users", null);
  const [user, setUser] = useLocalStorage("user", null);

  function generateRandomId() {
    return Math.random().toString(36).substring(2);
  }

  function clear() {
    localStorage.clear();
    setDoctorsUser(null);
    setAuthorities(null);
    setCredentials(null);
    setMachines(null);
    setProducts(null);
    setUrlLogIn(null);
    setDoctors(null);
    setUsers(null);
    setUser(null);
    console.info(new Date(), "Sesion limpiada");
  }

  const generateDoctor = (credentials, doctorsAPI) => {
    const newDoctors = credentials?.map((item) => {
      return {
        id: item?.user?.id,
        firstName: item?.user?.firstName || "",
        lastName: item?.user?.lastName || "",
        userName: item?.userName || "",
        email: item?.user?.email,
        profile: item,
      }
    })
    const newDoctorsUser = doctorsAPI?.map((item) => {
      const id = generateRandomId()
      const newData = {
        ...newDoctors?.find((item2) => item2.id === item.idUser),
        ...item,
        id: id,
      }
      return newData
    })
    return { newDoctors, newDoctorsUser }
  }

  const loadCredentials = async (token) => {
    //const doctorsAPI = await readDoctorAPI("", token);
    const credentialsAPI = await readCredentialAPI("?idAuthority=6", token);
    //const { newDoctors, newDoctorsUser } = generateDoctor(credentialsAPI?.data, doctorsAPI?.data)
    setCredentials(credentialsAPI.data);
    //setDoctorsUser(newDoctors);
    //setDoctors(newDoctorsUser);
    return credentialsAPI.data
  }

  const getUrlLogIn = (user) => {
    const foundPermission = PERMISSON.find((element) =>
      user?.roles?.some((item) => item.idAuthority === element.idAuthority)
    );
    return foundPermission?.urlLogIn || "/app/error/404";
  };

  const login = async (data) => {
    const login = await userLogin(data);
    if (login.status === 200) {
      // API'S
      const token = login.data.token;
      const result = await fetchUserData(token);
      await loadCredentials(token);
      const machines = await readMachineAPI("", token);
      const products = await readProductAPI("", token);
      const usersAll = await readEmployeeAPI("", token);
      //const authorities = await readAuthoritiesAPI("", token);
      // save data
      setUsers(usersAll.data);
      setAuthorities(PERMISSON);
      setMachines(machines.data);
      setProducts(products.data);
      setUser({ token: token, profile: result.data, expired: createFullDateTimeToken() });
      const d = getUrlLogIn(result?.data);
      setUrlLogIn(d)
      return d;
    } else {
      alert("credenciales incorrectas");
      return null;
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    clear();
    navigate(urlLogOut, { replace: true });
  };

  const value = {
    // etras
    generateRandomId,
    loadCredentials,
    generateDoctor,
    // User
    clear,
    user,
    login,
    logout,
    urlLogIn,
    // doctores habilitados
    doctors,
    setDoctors,
    // Doctores completo
    doctorsUser,
    setDoctorsUser,
    // Maquinas
    machines,
    setMachines,
    // Productos
    products,
    setProducts,
    // Usuarios
    users,
    setUsers,
    // Autoridades
    authorities,
    setAuthorities,
    // Credenciales doctores
    credentials,
    setCredentials,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
