import { ADMIN, FARMACIA, SYSTEM } from "./permisson";

export const sideBar = [
  /*
  {
    icon: "account_tree",
    name: "Pacientes",
    link: "pacientes",
    access: [ASISTENTE_MEDICO, MEDICO, SYSTEM, ADMIN],
  },
  {
    icon: "event",
    name: "Agenda",
    link: "agenda",
    access: [ASISTENTE_MEDICO, MEDICO, SYSTEM, ADMIN],
  },
  {
    icon: "description",
    name: "Recetas",
    link: "recetas",
    access: [MEDICO, SYSTEM, ADMIN],
  },
  */
  {
    name: "Productos",
    link: "productos",
    icon: "production_quantity_limits",
    access: [FARMACIA, SYSTEM, ADMIN],
  },
  {
    link: "maquinas",
    name: "Dispensadoras",
    icon: "point_of_sale",
    access: [SYSTEM, ADMIN],
  },
  {
    icon: "inventory",
    name: "Inventarios",
    link: "inventarios",
    access: [FARMACIA, SYSTEM, ADMIN],
  },
  {
    icon: "group",
    name: "Usuarios",
    link: "usuarios",
    access: [SYSTEM, ADMIN],
  },
  /*
  {
    icon: "group",
    name: "Doctores",
    link: "doctores",
    access: [SYSTEM, ADMIN],
  },
  */
];
